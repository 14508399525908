import { template as template_a98162163cd44f7c9c7476031bafde79 } from "@ember/template-compiler";
const FKControlMenuContainer = template_a98162163cd44f7c9c7476031bafde79(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
